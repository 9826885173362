// extracted by mini-css-extract-plugin
export var contactMeContainer = "ContactMePanel-module--contactMeContainer--1NnB0";
export var contactMeWrapper = "ContactMePanel-module--contactMeWrapper--3T41w";
export var slideIn = "ContactMePanel-module--slideIn--62vif";
export var closeBtnWrapper = "ContactMePanel-module--closeBtnWrapper--IGAC0";
export var baseBtn = "ContactMePanel-module--baseBtn--303L_";
export var closeBtn = "ContactMePanel-module--closeBtn--2z-Tk ContactMePanel-module--baseBtn--303L_";
export var downloadBtnWrapper = "ContactMePanel-module--downloadBtnWrapper--3amwV";
export var downloadBtn = "ContactMePanel-module--downloadBtn--3VaXg ContactMePanel-module--baseBtn--303L_";
export var greeting = "ContactMePanel-module--greeting--1NbMx";
export var contactItem = "ContactMePanel-module--contactItem--1UynO";
export var thankYouMessage = "ContactMePanel-module--thankYouMessage--1pvgE";
export var slideDown = "ContactMePanel-module--slideDown--1G9uJ";