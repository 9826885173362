import * as React from "react";
import {useState} from "react";
import HomePage from "../components/homepage/HomePage";
import Layout from "../components/shared/Layout";
import Meta from "../components/shared/Meta";
import CallToActionStrip from "../components/calltoactionstrip/CallToActionStrip";
import ContactMePanel from "../components/contactmepanel/ContactMePanel";

export default function IndexPage() {
    const [showPanel, setShowPanel] = useState(false);

    const togglePanel = (state) => setShowPanel(prevState => state ? state : !prevState);

    return (
        <Layout>
            <Meta title="Home"/>
            <HomePage/>
            <CallToActionStrip togglePanel={togglePanel}/>
            {showPanel && <ContactMePanel togglePanel={togglePanel}/>}
        </Layout>
    )
}
