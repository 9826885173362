import React, {useRef, useState} from "react";
import * as styles from "./ContactMePanel.module.css";

export default function ContactMePanel({togglePanel}) {
    const [dislayTksMsg, setDisplayTksMsg] = useState(false);

    const wrapperRef = useRef();

    const onCloseBtnHandler = () => {
        if (wrapperRef && wrapperRef.current) {
            wrapperRef.current.style.transform = 'translateY(120%)';
            setTimeout(togglePanel, 550);
        }
    }

    const onDownloadCV = () => {
        setDisplayTksMsg(true);
        window.open("/nhantran_resume.pdf", "_blank");
    }

    return (
        <div className={styles.contactMeContainer}>
            <div className={styles.contactMeWrapper} ref={wrapperRef}>
                <div className={styles.closeBtnWrapper}>
                    <button className={styles.closeBtn} onClick={onCloseBtnHandler}>
                        <i className="bi bi-x"/>
                    </button>
                </div>
                <div className={styles.greeting}>
                    Hey there!
                </div>
                <div>
                    <div>I can be reached via:</div>
                    <div className={styles.contactItem}>
                        <span>Email:</span>
                        <a href="mailto:nhantran94@gmail.com">
                            nhantran94@gmail.com
                        </a>
                    </div>
                    <div className={styles.contactItem}>
                        <span>LinkedIn:</span>
                        <a href="https://www.linkedin.com/in/nhan-tran-8a8b8379/" target="_blank" rel="noreferrer">
                            Nhan Tran
                        </a>
                    </div>
                </div>
                <div className={styles.downloadBtnWrapper}>
                    <div>You can also download my CV by clicking the button below</div>
                    <button className={styles.downloadBtn} onClick={onDownloadCV}>
                        DOWNLOAD
                    </button>
                    {dislayTksMsg &&
                    <div className={styles.thankYouMessage}>
                        Thank you and wish to hear from you soon!
                    </div>}
                </div>
            </div>
        </div>
    )
}