export const selfIntroduction = [
    {
        title: "Who am I?",
        content: "A Frontend developer who specializes in the latest frontend technology: React, Gatsby, NextJS, VueJS,... You name it, I can deliver!\n" +
            "A developer of Netcompany Vietnam.\n" +
            "A Frontend & React instructor at KMIN Academy Vietnam.\n" +
            "A Project Manager with focus on speed & quality.\n" +
            "An advocate of all things Serverless: AWS, GCloud, JAMStack...\n" +
            "A Master of Economics Degree holder with the ability to communicate in English and Japanese."
    },
    {
        title: "What are my services?",
        content: "All things Frontend-related!\n" +
            "Freelance services, which ranges from blogs, e-commerces to real-time web services with a team of professional developers to back me up.\n" +
            "Teaching & coaching, with an upcoming series of Youtube-exclusive Front-end lessons.\n"
    }
]