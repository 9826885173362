import React from "react";
import * as styles from "./CallToActionStrip.module.css";
import {Link} from "gatsby";
import {PathNames} from "../../utils/constants";

export default function CallToActionStrip({togglePanel}) {
    return (
        <div className={styles.buttonWrapper}>
            <Link to={PathNames["ABOUT ME"]} className={styles.actionButton}>
                <span>Read more about my story</span>
            </Link>
            <button className={styles.actionButton} onClick={() => togglePanel(true)}>
                <span>Contact me</span>
            </button>
        </div>
    )
}