import React, {Fragment} from "react";
import * as styles from "./HomePage.module.css";
import {selfIntroduction} from "../../data/homePagedata";

export default function HomePage() {
    return (
        <div className={styles.homePageWrapper}>
            <div className={styles.introductionBox}>
                <div className={styles.introductionContent}>
                    Welcome!
                </div>
                <div className={styles.introductionGrid}>
                    {selfIntroduction.map(data => (
                        <Fragment key={data.title}>
                            <div className={styles.titleColumn}>
                                {data.title}
                            </div>
                            <div className={styles.contentColumn}>
                                {data.content}
                            </div>
                        </Fragment>
                    ))}

                </div>
            </div>
            <div>
                <img className={styles.profileImage} src="/my-profile.jpg" alt="Nhan Tran" width={270}/>
            </div>
        </div>
    )
}